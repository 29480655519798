// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import RailsUjs from "@rails/ujs";
RailsUjs.start();
window.Turbolinks = require("turbolinks");
window.Turbolinks.start();
require("@rails/activestorage").start();
require("channels");

import $ from "jquery";
window.$ = $;
import "bootstrap/dist/js/bootstrap";
import Highcharts from 'highcharts';
window.Highcharts = Highcharts

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context("../images", true);
const imagePathIgnored = (name) => images(name, true);

// Stylesheets
require("../styles");

import "controllers";
